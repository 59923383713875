@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo bg-site-base text-white [&:not([disabled])]:focus:bg-site-accent;
  }

  .btn--alt {
    @apply btn--base btn--pseudo bg-transparent text-site-accent border border-site-accent [&:not([disabled])]:focus:bg-site-accent [&:not([disabled])]:focus:text-white;
  }

  .section {
    @apply py-6 lg:py-12;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors [&:not([disabled])]:hover:underline disabled:opacity-75;
  }

  .btn--base {
    @apply py-2 px-5 rounded-lg inline-flex font-light;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-site-accent;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content--paragraphs {
    @apply children-p:leading-6 children-p:mb-4 children-p:font-light children-p:text-site-base;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-site-base/75;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-site-accent;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn;
}

.form-duuf-group {
  @apply mb-5;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex-center;
}

.form-duuf-label {
  @apply block;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-md border border-black text-site-base;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--alt py-1 px-2;
}

/* Custom */
